import React, { memo } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import Seo from '../components/common/Seo';

const GraphQLError = loadable(() => import('../components/error'));
const Layout = loadable(() => import('../layout'));
const PromotionsContaier = loadable(() => import('../components/promotions_page'));

export const Head = ({ location, params, data, pageContext }) => {
  const {
    prismicPromotionsPage: {
      data: { meta_title, meta_description },
    },
  } = data;
  const seo = {
    title: meta_title,
    description: meta_description,
    keywords: [],
    location,
  };

  return <Seo {...seo} />;
};

const PromotionsPage = (props) => {
  const { data, errors, uri, location } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }

  const {
    prismicPromotionsPage: {
      data: { title_page, h1_header, body },
    },
  } = data;

  return (
    <Layout location={location}>
      <h1 hidden>{h1_header}</h1>

      <PromotionsContaier title_page={title_page} body={body} />
    </Layout>
  );
};

export default memo(PromotionsPage);

export const query = graphql`
  {
    prismicPromotionsPage {
      data {
        h1_header
        meta_description
        meta_title
        title_page
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...PromotionsPageDataBodyImageBlock
          ...PromotionsPageDataBodyPromotionsBlock
          ...PromotionsPageDataBodyTCBlock
        }
      }
    }
  }
`;
